import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { throwIfAlreadyLoaded } from '@nexweb/util';

import { ConnectTokenRequestInterceptor } from './connect-token-request.interceptor';

const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ConnectTokenRequestInterceptor,
    multi: true,
  },
];

@NgModule({
  imports: [CommonModule],
})
export class SharedInterceptorsConnectTokenRequestModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: SharedInterceptorsConnectTokenRequestModule,
  ) {
    throwIfAlreadyLoaded(parentModule, 'SharedInterceptorsConnectTokenRequestModule');
  }

  static forRoot(): ModuleWithProviders<SharedInterceptorsConnectTokenRequestModule> {
    return {
      ngModule: SharedInterceptorsConnectTokenRequestModule,
      providers: [httpInterceptorProviders],
    };
  }
}
