<div *ngIf="currentUser$ | async as user">
  <div *ngIf="user.isAuthenticated">
    <div class="system-detail content">
      <i class="fas fa-solid fa-clock padding-top2 right-padding5"></i>
      <span class="time-div">
        {{ serverTimeDisplay$ | async }}
      </span>
      <!-- <div *ngIf="useOnlyActiveRole">
        <i class="fas fa-user fa-lg right-padding2"></i>
        <span class="right-padding10">
          {{ 'Aum::Title:ActiveRole' | abpLocalization }} -
          {{ (activeRole | async)?.roleName }}
        </span>
      </div> -->
    </div>
  </div>
</div>
