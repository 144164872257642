import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@abp/ng.core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfigStateService } from '@abp/ng.core';

import { UserIdleService } from './user-idle.service';

@Injectable({
  providedIn: 'root',
})
export class SessionExpiryService {
  protected _timerStartSubscription: Subscription | null = null;

  private _showDialog: boolean;
  private _timerCountDown: number;
  private _authenticationSubscription$: Subscription;
  private _timeoutSubscription$: Subscription;
  private _logoutSubscription$: Subscription;

  constructor(
    public userIdle: UserIdleService,
    public authService: AuthService,
    private configStateService: ConfigStateService,
    public router: Router,
  ) {}

  get timerCountDown(): number {
    return this._timerCountDown;
  }
  get showDialog(): boolean {
    return this._showDialog;
  }

  /**
   * initialize and subscribe to
   * observables for timer start
   * and time out
   */
  public initialize() {
    const isAuthenticated$ = this.configStateService.getDeep$('currentUser.isAuthenticated');

    this._authenticationSubscription$ = isAuthenticated$
      .pipe(
        tap((authed) => {
          if (authed) {
            this.userIdle.startWatching();

            this._timerStartSubscription = this.userIdle
              .onTimerStart()
              .pipe(
                tap((result) => {
                  if (result) {
                    this._showDialog = true;
                  }
                }),
              )
              .subscribe((count) => (this._timerCountDown = count));
          } else {
            this.userIdle.stopWatching();

            if (this._timerStartSubscription) {
              this._timerStartSubscription.unsubscribe();
              this._timerStartSubscription = null;
            }
          }
        }),
      )
      .subscribe();

    this._timeoutSubscription$ = this.userIdle
      .onTimeout()
      .pipe(
        tap(() => {
          this.logOut();
        }),
      )
      .subscribe();
  }

  /**
   * continue browsing the site
   */
  public continueWorking() {
    this.userIdle.resetTimer();

    this.userIdle.startWatching();

    this._showDialog = false;
  }

  /**
   * log out of the site
   */
  public logOut() {
    this.userIdle.stopWatching();

    this._logoutSubscription$ = this.authService.logout().subscribe();

    this.router.navigate(['/']);

    this._showDialog = false;
  }

  /*
   * clear login local storage data
   */
  public clearLoginLocalStorage(): void {
    if (window.localStorage.getItem('access_token')) {
      window.localStorage.removeItem('access_token');
    }

    if (window.localStorage.getItem('access_token_stored_at')) {
      window.localStorage.removeItem('access_token_stored_at');
    }

    if (window.localStorage.getItem('expires_at')) {
      window.localStorage.removeItem('expires_at');
    }

    if (window.localStorage.getItem('refresh_token')) {
      window.localStorage.removeItem('refresh_token');
    }
  }

  onDestroy() {
    if (this._authenticationSubscription$) {
      this._authenticationSubscription$.unsubscribe();
    }
    if (this._timeoutSubscription$) {
      this._timeoutSubscription$.unsubscribe();
    }

    if (this._logoutSubscription$) {
      this._logoutSubscription$.unsubscribe();
    }
  }
}
