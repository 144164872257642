import { Injectable } from '@angular/core';

import { ConfigStateService, RoutesService, isNullOrUndefined } from '@abp/ng.core';
import { tap } from 'rxjs/operators';

import { createEffect } from '@nexweb/shared/effects';
import { FeatureFlagNames } from '@nexweb/shared/guards/feature-flag';

import { dayAheadMarketMenuName, forwardPhysicalMarketMenuName } from './constants';

@Injectable({ providedIn: 'root' })
export class AmtMenuEffects {
  dayAheadMenuEffect$ = createEffect(() =>
    this.configStateService.getFeature$(FeatureFlagNames.dayAheadMarketEnabled).pipe(
      tap((featureFlag) => {
        if (!isNullOrUndefined(featureFlag)) {
          const featureValue = JSON.parse(featureFlag);

          this.routes.patch(dayAheadMarketMenuName, {
            invisible: !featureValue,
          });
        }
      }),
    ),
  );

  forwardPhysicalMenuEffect$ = createEffect(() =>
    this.configStateService
      .getFeatures$([FeatureFlagNames.monthlyForwardMarketEnabled, FeatureFlagNames.weeklyForwardMarketEnabled])
      .pipe(
        tap((values: Record<string, string> | undefined) => {
          if (!isNullOrUndefined(values)) {
            const monthlyKey = FeatureFlagNames.monthlyForwardMarketEnabled;
            const weeklyKey = FeatureFlagNames.weeklyForwardMarketEnabled;

            if (values && !isNullOrUndefined(values[monthlyKey]) && !isNullOrUndefined(values[weeklyKey])) {
              const monthlyForwardFeatureValue = JSON.parse(values[monthlyKey]);
              const weeklyForwardFeatureValue = JSON.parse(values[weeklyKey]);

              this.routes.patch(forwardPhysicalMarketMenuName, {
                invisible: !monthlyForwardFeatureValue && !weeklyForwardFeatureValue,
              });
            }
          }
        }),
      ),
  );

  constructor(
    private configStateService: ConfigStateService,
    private routes: RoutesService,
  ) {}
}
