import { Inject, Injectable } from '@angular/core';

import { OAuthService } from 'angular-oauth2-oidc';

import { NotificationTypeEnum } from '@nexweb/shared/data-access/mdd';
import { BaseSignalR, SignalRHubNames } from '@nexweb/shared/signalr-helper';
import { API_URL_TOKEN } from '@nexweb/shared/tokens/api-url';

import { ErrorNotificationService } from './error-notification.service';
import { SystemNotificationService } from './system-notification.service';

@Injectable({
  providedIn: 'root',
})
export class SystemMessagingHubService extends BaseSignalR {
  constructor(
    @Inject(API_URL_TOKEN) apiUrl: string,
    private oAuthService: OAuthService,
    private errorNotificationService: ErrorNotificationService,
    private systemNotificationService: SystemNotificationService,
  ) {
    super(apiUrl);
  }

  public initialize(): void {
    this.stopConnection();
    this.buildConnection(SignalRHubNames.systemMessaging, this.oAuthService.getAccessToken());
    this.startConnection();
    this.initEvents();
  }

  protected initEvents(): void {
    this.hubConnection.on(
      'NotifyUser',
      (
        messageId: number,
        messageText: string,
        notificationComponent: NotificationTypeEnum,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        parameters: { ErrorMessage: string; BidId: number },
      ) => {
        switch (notificationComponent) {
          case NotificationTypeEnum.systemNotification:
            this.systemNotificationService.show(messageText);
            break;
          case NotificationTypeEnum.errorNotification:
            this.errorNotificationService.show(parameters.ErrorMessage);
            break;
          default:
            break;
        }
      },
    );
  }
}
