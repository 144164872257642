import { Injectable } from '@angular/core';

import { ConfigStateService, RoutesService, isNullOrUndefined } from '@abp/ng.core';
import { tap } from 'rxjs/operators';

import { createEffect } from '@nexweb/shared/effects';
import { FeatureFlagNames } from '@nexweb/shared/guards/feature-flag';

import {
  scadaFileGenerateLogMenuName,
  scadaFileLoadLogMenuName,
  scadaSchedulerLogMenuName,
  thirdPartyIntegrationMenuName,
} from './constants';

@Injectable({ providedIn: 'root' })
export class SappMtpMenuEffects {
  sappMtpMenuEffect$ = createEffect(() =>
    this.configStateService.getFeature$(FeatureFlagNames.scadaIntegrationEnabled).pipe(
      tap((featureFlag) => {
        if (!isNullOrUndefined(featureFlag)) {
          const featureValue = JSON.parse(featureFlag);

          this.routes.patch(thirdPartyIntegrationMenuName, {
            invisible: !featureValue,
          });

          this.routes.patch(scadaFileLoadLogMenuName, {
            invisible: !featureValue,
          });

          this.routes.patch(scadaFileGenerateLogMenuName, {
            invisible: !featureValue,
          });

          this.routes.patch(scadaSchedulerLogMenuName, {
            invisible: !featureValue,
          });
        }
      }),
    ),
  );

  constructor(
    private configStateService: ConfigStateService,
    private routes: RoutesService,
  ) {}
}
