import { Injectable } from '@angular/core';

import { ConfigStateService, RoutesService, isNullOrUndefined } from '@abp/ng.core';
import { tap } from 'rxjs/operators';

import { createEffect } from '@nexweb/shared/effects';
import { FeatureFlagNames } from '@nexweb/shared/guards/feature-flag';

import {
  etsEnergySettlementMenuName,
  etsFinancialSettlementMenuName,
  etsOperationalReportingMenuName,
  operationalReportingMenuName,
  sappMarketSettlementResultsMenuName,
  settlementsDashboardMenuName,
  settlementsMenuName,
} from './constants';

@Injectable({ providedIn: 'root' })
export class SetrepMenuEffects {
  operationalReportingMenuEffect$ = createEffect(() =>
    this.configStateService.getFeature$(FeatureFlagNames.operationalReportingEnabled).pipe(
      tap((featureFlag) => {
        if (!isNullOrUndefined(featureFlag)) {
          const featureValue = JSON.parse(featureFlag);

          this.routes.patch(operationalReportingMenuName, {
            invisible: !featureValue,
          });
          this.routes.patch(etsOperationalReportingMenuName, {
            invisible: !featureValue,
          });
        }
      }),
    ),
  );

  energySchedulingMenuEffect$ = createEffect(() =>
    this.configStateService.getFeature$(FeatureFlagNames.energySettlementEnabled).pipe(
      tap((featureFlag) => {
        if (!isNullOrUndefined(featureFlag)) {
          const featureValue = JSON.parse(featureFlag);

          this.routes.patch(etsEnergySettlementMenuName, {
            invisible: !featureValue,
          });
        }
      }),
    ),
  );

  financialSchedulingMenuEffect$ = createEffect(() =>
    this.configStateService.getFeature$(FeatureFlagNames.financialSettlementEnabled).pipe(
      tap((featureFlag) => {
        if (!isNullOrUndefined(featureFlag)) {
          const featureValue = JSON.parse(featureFlag);

          this.routes.patch(etsFinancialSettlementMenuName, {
            invisible: !featureValue,
          });

          this.routes.patch(settlementsMenuName, {
            invisible: !featureValue,
          });
        }
      }),
    ),
  );

  sappMarketResultsMenuEffect$ = createEffect(() =>
    this.configStateService
      .getFeatures$([FeatureFlagNames.financialSettlementEnabled, FeatureFlagNames.etsMtpIntegrationEnabled])
      .pipe(
        tap((values: Record<string, string> | undefined) => {
          if (!isNullOrUndefined(values)) {
            const finSetKey = FeatureFlagNames.financialSettlementEnabled;
            const etsMtpKey = FeatureFlagNames.etsMtpIntegrationEnabled;

            if (values && !isNullOrUndefined(values[finSetKey]) && !isNullOrUndefined(values[etsMtpKey])) {
              const finSetFeatureValue = JSON.parse(values[finSetKey]);
              const etsMtpFeatureValue = JSON.parse(values[etsMtpKey]);

              this.routes.patch(sappMarketSettlementResultsMenuName, {
                invisible: !etsMtpFeatureValue || !finSetFeatureValue,
              });
            }
          }
        }),
      ),
  );

  settlementDashboardMenuEffect$ = createEffect(() =>
    this.configStateService.getFeatures$([FeatureFlagNames.settlementDashboardEnabled]).pipe(
      tap((values: Record<string, string> | undefined) => {
        if (!isNullOrUndefined(values)) {
          const settlementDashboardKey = FeatureFlagNames.settlementDashboardEnabled;

          if (values && !isNullOrUndefined(values[settlementDashboardKey])) {
            const settlementDashboardFeatureValue = JSON.parse(values[settlementDashboardKey]);

            this.routes.patch(settlementsDashboardMenuName, {
              invisible: !settlementDashboardFeatureValue,
            });
          }
        }
      }),
    ),
  );

  constructor(
    private configStateService: ConfigStateService,
    private routes: RoutesService,
  ) {}
}
