import { Injectable } from '@angular/core';

import { ConfigStateService, RoutesService, isNullOrUndefined } from '@abp/ng.core';
import { tap } from 'rxjs/operators';

import { createEffect } from '@nexweb/shared/effects';
import { FeatureFlagNames } from '@nexweb/shared/guards/feature-flag';

import {
  manageMarketsInterconAllocationDashboard,
  marketSurveillanceMenuName,
  ttcDeviationDashboard,
  ttcDeviationStats,
} from './constants';

@Injectable({ providedIn: 'root' })
export class MsMenuEffects {
  marketSurveilance$ = createEffect(() =>
    this.configStateService.getFeature$(FeatureFlagNames.marketSurveillanceEnabled).pipe(
      tap((featureFlag) => {
        if (!isNullOrUndefined(featureFlag)) {
          const featureValue = JSON.parse(featureFlag);

          this.routes.patch(marketSurveillanceMenuName, {
            invisible: !featureValue,
          });

          this.routes.patch(manageMarketsInterconAllocationDashboard, {
            invisible: !featureValue,
          });

          this.routes.patch(ttcDeviationDashboard, {
            invisible: !featureValue,
          });

          this.routes.patch(ttcDeviationStats, {
            invisible: !featureValue,
          });
        }
      }),
    ),
  );

  constructor(
    private configStateService: ConfigStateService,
    private routes: RoutesService,
  ) {}
}
