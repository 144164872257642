import { Injectable } from '@angular/core';

import { ReplaceableComponentsService } from '@abp/ng.core';
import { eThemeLeptonXComponents } from '@volosoft/abp.ng.theme.lepton-x';

import { NavItemsComponent, SettingsItemsComponent } from '../components';

@Injectable({
  providedIn: 'root',
})
export class ReplaceComponentsService {
  constructor(private replaceableComponentsService: ReplaceableComponentsService) {}

  public replaceAbpComponents(): void {
    this.replaceNavItemsComponent();

    this.replaceSettingsComponent();
  }

  private replaceNavItemsComponent(): void {
    this.replaceableComponentsService.add({
      component: NavItemsComponent,
      key: eThemeLeptonXComponents.Breadcrumb,
    });
  }

  private replaceSettingsComponent(): void {
    this.replaceableComponentsService.add({
      component: SettingsItemsComponent,
      key: eThemeLeptonXComponents.Settings,
    });
  }
}
