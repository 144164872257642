<div class="d-flex justify-content-between">
  <div>
    <abp-breadcrumb></abp-breadcrumb>
  </div>
  <div class="d-flex">
    <div>
      <!-- system details-->
      <nexweb-system-details></nexweb-system-details>
    </div>
    <div>
      <!-- environment -->
      <nexweb-environment-indicator></nexweb-environment-indicator>
    </div>
  </div>
</div>
