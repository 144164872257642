import { Environment } from '@abp/ng.core';

const baseUrl = window['__env']?.baseUrl || 'http://localhost:4200';

export const environment = {
  production: true,
  application: {
    baseUrl,
    name: window['__env']?.applicationName || 'NexBase',
  },
  oAuthConfig: {
    issuer: window['__env']?.oAuthConfigIssuer || 'https://localhost:44336/',
    redirectUri: baseUrl,
    clientId: window['__env']?.oAuthConfigClientId || 'NexBase_App',
    scope: window['__env']?.oAuthConfigScope || 'offline_access NexBase',
    showDebugInformation: false,
    oidc: false,
    requireHttps: true,
  },
  apis: {
    default: {
      url: window['__env']?.apiUrl || 'https://localhost:44336',
      rootNamespace: 'NexBase',
    },
  },
  localization: {
    defaultResourceName: window['__env']?.defaultResourceName || 'NexBase',
  },
} as Environment;
