import { Component, OnInit, TrackByFunction } from '@angular/core';

import { isNullOrUndefined } from '@abp/ng.core';
import { NavItem, NavItemsService } from '@abp/ng.theme.shared';
import { Observable } from 'rxjs';

import { MessageInboxComponent } from '../message-inbox/message-inbox.component';

@Component({
  selector: 'nexweb-nav-items',
  templateUrl: './nav-items.component.html',
})
export class NavItemsComponent implements OnInit {
  private readonly _messageInboxId = 'MessageInboxComponent';
  // private readonly _languagesId = 'Theme.LanguagesComponent';

  private messageInboxNavItem: NavItem = {
    id: this._messageInboxId,
    component: MessageInboxComponent,
    order: 99.99,
  };
  // private languagesNavItemComponent: NavItem = {
  //   id: this._languagesId,
  //   component: LanguagesComponent,
  // };

  private _navItems: NavItem[] = [];

  constructor(private navItemService: NavItemsService) {
    // this.navItemService.patchItem(this._languagesId, this.languagesNavItemComponent);
  }

  ngOnInit(): void {
    const messageInbox = this.navItemService.items.find((x) => x.id === this._messageInboxId);
    if (isNullOrUndefined(messageInbox)) {
      this._navItems.push(this.messageInboxNavItem);
    }

    this.navItemService.addItems(this._navItems);
  }

  trackByFn: TrackByFunction<NavItem> = (_, element) => element?.id;

  public getNavItems$(): Observable<NavItem[]> {
    return this.navItemService.items$;
  }
}
