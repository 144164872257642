import { Inject, InjectionToken, Injector, ModuleWithProviders, NgModule, Type } from '@angular/core';

import { isObservable } from 'rxjs';

import { isEffect } from './is-effect';

const EFFECTS_PROVIDERS = new InjectionToken('EFFECTS_PROVIDERS');

@NgModule({})
export class EffectsModule {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(@Inject(EFFECTS_PROVIDERS) providers: Type<any>[], injector: Injector) {
    for (const provider of providers) {
      const currentProvider = injector.get(provider);

      for (const effectFactory of Object.values(currentProvider)) {
        if (isEffect(effectFactory)) {
          const factory = effectFactory();

          if (isObservable(factory)) {
            factory.subscribe();
          }
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static register(providers: Type<any>[]): ModuleWithProviders<EffectsModule> {
    return {
      ngModule: EffectsModule,
      providers: [
        {
          provide: EFFECTS_PROVIDERS,
          useValue: providers,
          // can also use `multi: true` if you want to use
          // it in eager modules
        },
      ],
    };
  }
}
