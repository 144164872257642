import { Component, OnDestroy, OnInit } from '@angular/core';

import { AuthService, ConfigStateService } from '@abp/ng.core';
import { FooterLink, FooterLinksService, FooterNav } from '@volo/ngx-lepton-x.core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Subject, combineLatest, filter, takeUntil } from 'rxjs';

import { ReplaceComponentsService } from '@nexweb/shared/abp/ui';
import { BmSystemMessagingHubService } from '@nexweb/shared/bm-system-messaging-services';
import { FeatureFlagNames } from '@nexweb/shared/guards/feature-flag';
import { SystemMessagingHubService } from '@nexweb/shared/system-messaging';
import { SessionExpiryService } from '@nexweb/shared/ui/user-idle';

@Component({
  selector: 'nexweb-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy, OnInit {
  private _onDestroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private oAuthService: OAuthService,
    private sessionExpiryService: SessionExpiryService,
    private configStateService: ConfigStateService,
    private systemMessagingHubService: SystemMessagingHubService,
    private bmSystemMessagingHubService: BmSystemMessagingHubService,
    private replaceComponentsService: ReplaceComponentsService,
    private footerLinksService: FooterLinksService,
  ) {
    this.initSessionExpiry();
  }

  /*
   * When user closes browser window
   * Remove all browser window local storage data
   */
  // @HostListener('window:unload', ['$event'])
  // unloadHandler(event) {
  //   if (window.onclose) {
  //     this.sessionExpiryService.clearLoginLocalStorage();
  //   }
  // }

  ngOnInit(): void {
    this.listenForLogout();
    this.initSystemMessagingHub();
    this.initBmSystemMessagingHub();
    this.replaceComponentsService.replaceAbpComponents();
    this.initFooterItems();
  }

  listenForLogout(): void {
    // sometimes the logout event is not triggered by abp auth service
    this.oAuthService.events
      .pipe(filter((e) => e.type === 'logout'))
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        this.authService.navigateToLogin();
      });
  }

  ngOnDestroy(): void {
    this.sessionExpiryService.onDestroy();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private initSessionExpiry(): void {
    const userIdleActive = JSON.parse(this.configStateService.getSetting('NexBase.UserIdle.Enabled'));
    if (userIdleActive) {
      this.sessionExpiryService.initialize();
    }
  }

  private initSystemMessagingHub(): void {
    this.configStateService
      .getOne$('currentUser')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((currentUser) => {
        if (currentUser.isAuthenticated) {
          this.systemMessagingHubService.initialize();
        } else {
          this.systemMessagingHubService.stopConnection();
        }
      });
  }

  private initBmSystemMessagingHub(): void {
    combineLatest([
      this.configStateService.getOne$('currentUser'),
      this.configStateService.getFeature$(FeatureFlagNames.balancingMarketEnabled),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([currentUser, featureFlag]) => {
        if (currentUser.isAuthenticated && JSON.parse(featureFlag)) {
          this.bmSystemMessagingHubService.initialize();
        } else {
          this.bmSystemMessagingHubService.stopConnection();
        }
      });
  }

  private initFooterItems(): void {
    const footerLinks = [] as FooterLink[];

    if (window['__env']?.abpVersion) {
      footerLinks.push({ link: '', text: `Abp: ${window['__env']?.abpVersion}` } as FooterLink);
    }

    if (window['__env']?.buildNumber) {
      footerLinks.push({ link: '', text: `Build: ${window['__env']?.buildNumber}` } as FooterLink);
    }

    if (footerLinks.length > 0) {
      const footerInfo: FooterNav = {
        desc: '',
        descUrl: '',
        footerLinks: footerLinks,
      };

      this.footerLinksService.setFooterInfo(footerInfo);
    }
  }
}
