/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeDetectionStrategy, Component, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { LocalizationService } from '@abp/ng.core';

const CUSTOM_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AcknowledgeMessageReadComponent),
  multi: true,
};

@Component({
  selector: 'nexweb-acknowledge-message-read',
  templateUrl: './acknowledge-message-read.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CUSTOM_VALUE_ACCESSOR],
})
export class AcknowledgeMessageReadComponent implements OnInit, ControlValueAccessor {
  @ViewChild('checkbox') checkbox: HTMLInputElement;

  acknowledgementMessage: string;
  value = false;

  constructor(private localizationService: LocalizationService) {}

  onChange = (_: unknown) => {};
  onTouched = (_: unknown) => {};

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.checkbox.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.acknowledgementMessage = this.localizationService.instant({
      key: 'Mdd::AcknowledgeMessageRead',
      defaultValue: 'I acknowledge that I have read this message.',
    });
  }
}
