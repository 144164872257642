import { Injectable } from '@angular/core';

import { LanguageService } from '@volo/ngx-lepton-x.core';
import { ThemeService } from '@volosoft/ngx-lepton-x';
import {
  Setting,
  SettingsService,
  SideMenuLayoutService,
  combineAndFilterByChildren,
} from '@volosoft/ngx-lepton-x/layouts';
import { Observable, combineLatest, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarSettingsService implements SettingsService {
  // remove all settings options, this will also hide the settings button
  settings$: Observable<Setting[]> = combineAndFilterByChildren([
    //this.themeService.stylesAsSettingsGroup$,
    this.layoutService.layoutsAsSettingsGroup$,
    this.languageService.languagesAsSettingsGroup$,
  ]);

  selectedSettings$ = combineLatest([
    this.themeService.selectedStyle$.pipe(
      map((style) => ({
        icon: style.icon,
        id: this.themeService.id,
      })),
    ),
    this.layoutService.selectedLayout$.pipe(
      map((layout) => ({
        icon: layout.icon,
        id: this.layoutService.id,
      })),
    ),
    this.languageService.selectedLanguage$,
  ]);

  constructor(
    private themeService: ThemeService,
    private languageService: LanguageService,
    private layoutService: SideMenuLayoutService,
  ) {}
}
