import { Injectable } from '@angular/core';

import { ConfigStateService, RoutesService, isNullOrUndefined } from '@abp/ng.core';
import { tap } from 'rxjs/operators';

import { createEffect } from '@nexweb/shared/effects';
import { FeatureFlagNames } from '@nexweb/shared/guards/feature-flag';

import {
  bilateralSchedulesMenuName,
  scadaInterconeMenuName,
  schedVScadaMenuName,
  txAllocationManagementMenuName,
  viewInterconIntervalMenuName,
} from './constants';

@Injectable({ providedIn: 'root' })
export class TsamMenuEffects {
  tsamMenuEffect$ = createEffect(() =>
    this.configStateService.getFeature$(FeatureFlagNames.txAllocationManagementEnabled).pipe(
      tap((featureFlag) => {
        if (!isNullOrUndefined(featureFlag)) {
          const featureValue = JSON.parse(featureFlag);

          this.routes.patch(txAllocationManagementMenuName, {
            invisible: !featureValue,
          });
          this.routes.patch(bilateralSchedulesMenuName, {
            invisible: !featureValue,
          });
          this.routes.patch(viewInterconIntervalMenuName, {
            invisible: !featureValue,
          });
          this.routes.patch(viewInterconIntervalMenuName, {
            invisible: !featureValue,
          });
          this.routes.patch(viewInterconIntervalMenuName, {
            invisible: !featureValue,
          });
        }
      }),
    ),
  );

  scadaMenuEffect$ = createEffect(() =>
    this.configStateService.getFeature$(FeatureFlagNames.scadaIntegrationEnabled).pipe(
      tap((featureFlag) => {
        if (!isNullOrUndefined(featureFlag)) {
          const featureValue = JSON.parse(featureFlag);

          this.routes.patch(scadaInterconeMenuName, {
            invisible: !featureValue,
          });
          this.routes.patch(schedVScadaMenuName, {
            invisible: !featureValue,
          });
        }
      }),
    ),
  );

  constructor(
    private configStateService: ConfigStateService,
    private routes: RoutesService,
  ) {}
}
